<template>
  <div class="row custom-calender hidden">
    <div class="col-md-4">
      <label for="">Check In</label>
      <div class="calendar-input-group">
        <span class="icon">
          <img src="../../assets/icons/calendar.svg" alt="calendar" />
        </span>
        <b-form-input
          readonly
          @click="showCalendar = !showCalendar"
          class="input check-in has-icon"
          type="text"
          :value="date.checkIn"
        />
      </div>
    </div>
    <div class="col-md-4">
      <label for="">Check Out</label>
      <div class="calendar-input-group">
        <span class="icon">
          <img src="../../assets/icons/calendar.svg" alt="calendar" />
        </span>
        <b-form-input
          readonly
          @click="showCalendar = !showCalendar"
          class="input check-out has-icon"
          type="text"
          :value="date.checkOut"
        />
      </div>
    </div>
    <div class="col-md-4 mt-auto">
      <button class="btn btn-main btn-block py-2">Search</button>
    </div>
    <div v-if="showCalendar" class="col-md-8 mt-1">
      <HotelDatePicker
        :alwaysVisible="true"
        @period-selected="populateDate"
        format="DD/MM/YYYY"
      >
      </HotelDatePicker>
    </div>
    <div class="col-md-12 mt-2 promo">
      <img src="../../assets/icons/promo.svg" class="mr-1" alt="promo" />
      <span>Have a promo code?</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import HotelDatePicker from "vue-hotel-datepicker";
import "vue-hotel-datepicker/dist/vueHotelDatepicker.css";
export default {
  name: "BookingEngineDateSelector",
  props: {
    checkIn: {
      type: String,
      required: false,
    },
    checkOut: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showCalendar: false,
      date: {
        checkIn: null,
        checkOut: null,
      },
    };
  },
  mounted() {
    document.addEventListener("click", (event) => {
      const calendar = document.querySelector(".vhd__datepicker");
      const checkIn = document.querySelector(".check-in");
      const checkOut = document.querySelector(".check-out");
      if (
        calendar &&
        !calendar.contains(event.target) &&
        !checkIn.contains(event.target) &&
        !checkOut.contains(event.target)
      ) {
        this.showCalendar = false;
      }
    });
    setTimeout(() => {
      const { checkIn, checkOut } = this;
      if (checkIn && checkOut) {
        this.date.checkIn = moment(checkIn, "DD-MM-YYYY").format("DD MMM YYYY");
        this.date.checkOut = moment(checkOut, "DD-MM-YYYY").format("DD MMM YYYY");
      }
    }, 100);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  components: {
    HotelDatePicker,
  },
  methods: {
    populateDate(e, checkIn, checkOut) {
      this.date.checkIn = moment(checkIn).format("DD MMM YYYY");
      this.date.checkOut = moment(checkOut).format("DD MMM YYYY");
    },
  },
};
</script>
